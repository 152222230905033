<template>
	<main>
		<section class="page404">
			<div class="container">
				<div class="left">
					<div class="title">Дуже шкода!</div>
					<div class="sub">помилка 404</div>
				</div>
				<div class="right">
					<p>
						Можливо, сторінку, яку ви шукаєте, було переміщено або видалено.
					</p>
					<!-- <p>
						Почніть новий пошук на biopharma.ua або відвідайте один з популярних
						розділів нижче.
					</p> -->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "Page404",
};
</script>

<style lang="scss" scoped>
.page404 {
	padding: 60px 0;
	.container {
		@include container;
		display: grid;
		grid-template-columns: 25% 1fr;
		@media (max-width: $tl) {
			display: block;
		}
		.left {
			.title {
				font-style: normal;
				font-weight: 300;
				font-size: 40px;
				line-height: 88%;
				letter-spacing: -0.025em;
				color: #111325;
			}
			.sub {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 135%;
				letter-spacing: 0.01em;
				color: #111325;
				margin-top: 10px;
			}
		}
		.right {
			@include t2l;
		}
	}
}
</style>